<template>
  <div>
    <v-row>
      <v-col
        cols="24"
      >
        <dashboard-statistics-card></dashboard-statistics-card>
      </v-col>
    </v-row>
<!--    <v-row>-->
<!--      <v-col-->
<!--        cols="12"-->
<!--        md="6"-->
<!--        sm="12"-->
<!--        order="6"-->
<!--      >-->
<!--        <dashboard-total-sales-card></dashboard-total-sales-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </div>
</template>

<script>

import DashboardStatisticsCard from '@/views/dashboard/dashboard-statistics-card'
import DashboardTotalSalesCard from '@/views/dashboard/dashboard-total-sales-card'

export default {
  name: 'Dashboard',
  components: { DashboardTotalSalesCard, DashboardStatisticsCard },
  created() {
    document.title = 'Главная'
  }
}
</script>
