<template>
  <v-card>
    <v-card-title class="align-start flex-nowrap">
      <div>
        <p class="text-sm font-weight-semibold mb-2 ">
          Общие продажи
        </p>
        <p class="text-no-wrap">
          <span class="text-2xl font-weight-semibold me-1">$21,845</span>
        </p>
      </div>
      <v-spacer></v-spacer>

      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="18">
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(orderYear, i) in orderYearsDropDown"
            :key="i"
          >
            <v-list-item-title>{{ orderYear.year }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-card-title>

    <!--    <v-card-text>-->
    <!--      <vue-apex-charts-->
    <!--        height="180"-->
    <!--        :options="chartOptions"-->
    <!--        :series="chartData"-->
    <!--      ></vue-apex-charts>-->
    <!--    </v-card-text>-->
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  mdiDotsVertical,
} from '@mdi/js'

export default {
  name: 'DashboardTotalSalesCard',
  data: () => ({
    icons: {
      mdiDotsVertical,
    },
  }),
  async mounted() {
    await this.fetchOrdersYears()
  },
  computed: {
    ...mapGetters('dictionary', { orderYearsDropDown: 'orderYearsDropDown' }),
  },
  methods: {
    ...mapActions('dictionary', {
      fetchOrdersYears: 'fetchOrdersYears',
    }),
  },
}
</script>

<style scoped>

</style>
