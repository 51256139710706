<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Статистика</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon class="cursor-pointer">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="primary"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiTrendingUp }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              Продажи
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ totalSales }}
            </h3>
          </div>
        </v-col>

        <v-col
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="success"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              Клиенты
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ customerCount }}
            </h3>
          </div>
        </v-col>

        <v-col
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="warning"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiLabelOutline }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              Товары
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ dishCount }}
            </h3>
          </div>
        </v-col>

        <v-col
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="info"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              Средняя сумма заказа
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ averageChequeSum.toFixed(2) }}
            </h3>
          </div>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiTrendingUp, mdiAccountOutline, mdiLabelOutline, mdiCurrencyUsd } from '@mdi/js'
import { rules } from '@/helpers/validation'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions } from 'vuex'

export default {
  name: 'DashboardStatisticsCard',
  data: () => ({
    icons: {
      mdiTrendingUp,
      mdiAccountOutline,
      mdiLabelOutline,
      mdiCurrencyUsd,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('reportStatistics', [
      'customerCount',
      'totalSales',
      'dishCount',
      'averageChequeSum',
    ]),
  },
  async created() {
    await this.fetch()
  },
  methods: {
    ...mapActions('reportStatistics', ['fetch']),
  },
}
</script>

